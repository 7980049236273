import React from "react";
import { useRouter } from "next/router";
import { setCookie } from "nookies";

import { BaseModalInterface } from "@/components/Modals/Modal.interface";
import AlertIcon from "@/icons/18.svg";
import Typography from "@/components/Typography";
import BaseModal from "@/components/Modals/BaseModal";
import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";
import { MatureArticleModalInterface } from "@/components/Modals/MatureArticleModal/MatureArticleModal.interface";

import styles from "./MatureArticleModal.module.scss";

export const MatureArticleModal: React.FC<BaseModalInterface> = ({
  isOpen,
  onClose,
  callback,
}) => {
  const router = useRouter();

  const cancel = () => {
    router.push("/").then(() => {
      onClose();
    });
  };

  const handleCheckboxChange = async (e: any) => {
    setCookie(
      null,
      MatureArticleModalInterface.AgeConfirmation,
      e.target.checked,
      {
        path: "/",
        maxAge: 140 * 24 * 60 * 60,
      }
    );
  };

  const handleConfirm = () => {
    onClose();
    callback && callback();
  };

  return (
    <BaseModal
      onClose={onClose}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
    >
      <div className={styles.children}>
        <AlertIcon className={styles.icon} />

        <Typography variant="h2" component="p">
          Treści dla osób pełnoletnich
        </Typography>

        <Typography variant="medium" component="p" className={styles.text}>
          Żeby wejść na tę stronę lub odsłuchać podcast, musisz posiadasz co
          najmniej 18 lat. W przeciwnym razie przekierujemy cię na stronę
          główną.
        </Typography>

        <div className={styles.checkbox}>
          <Checkbox
            name={MatureArticleModalInterface.AgeConfirmation}
            onClick={handleCheckboxChange}
          />

          <Typography variant="small" component="p" className={styles.label}>
            Nie pytaj ponownie
          </Typography>
        </div>
      </div>

      <div className={styles.actions}>
        <Button variant="secondary" onClick={cancel}>
          Nie mam 18 lat
        </Button>

        <Button variant="primary" onClick={handleConfirm}>
          Mam 18 lat
        </Button>
      </div>
    </BaseModal>
  );
};

export default MatureArticleModal;
