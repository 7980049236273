import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/pl";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.locale("pl");

const oneMonthDifference = 2721600000;

export function formatDate(date: string): string {
  const today = new Date();
  const isMonthOld = dayjs(today).diff(date) < oneMonthDifference;

  if (isMonthOld) {
    return `${dayjs(date).from(dayjs(), true)} temu`;
  }

  return dayjs(date).format("DD.MM.YYYY");
}
