import { InputHTMLAttributes, Ref } from "react";

import { ClassName } from "@/helpers/pageHelpers/Common/interface";

export enum CheckboxVersion {
  first = "first",
  second = "second",
}

export interface CheckboxInterface extends InputHTMLAttributes<string> {
  className?: ClassName;
  error?: boolean;
  ref?: Ref<HTMLInputElement>;
  onClick?: (e: any) => void;
  version?: CheckboxVersion;
}
