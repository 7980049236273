import React from "react";
import classnames from "classnames";

import { ProgressBarInterface } from "./ProgressBar.interface";
import styles from "./ProgressBar.module.scss";

export const ProgressBar: React.FC<ProgressBarInterface> = ({
  duration,
  currentTime = 0,
  isDark,
  isActivePlayer,
  variant,
  className,
}) => {
  const progressPercentage =
    parseFloat((currentTime / duration).toFixed(3)) * 100;

  return (
    <div
      className={classnames(
        styles["progress-bar"],
        isDark && styles.dark,
        isActivePlayer && styles.animate,
        styles[variant],
        className
      )}
    >
      <span
        style={{
          width: progressPercentage + `%`,
        }}
        className={styles.progress}
      />
    </div>
  );
};

export default ProgressBar;
