import { useStoreState } from "pullstate";
import { useState, useEffect } from "react";

import { UIStore } from "@/store/UIStore";
import client from "@/helpers/apiClient";
import { apiPath } from "@/helpers/apiPath";
import {
  ContentSourceEnum,
  UITypes,
} from "@/components/BookmarkButton/BookmarkButton.interface";
import { SlugType } from "@/helpers/pageHelpers/Common/interface";
import { getBookmarkedEpisodes } from "@/helpers/getBookmarkedEpisodes";
import { useDataLayer } from "@/helpers/useDataLayer";

export const useBookmark = (
  slug: SlugType,
  source: ContentSourceEnum,
  bookmarked?: boolean,
  uiType?: UITypes
) => {
  const { pushToDataLayer } = useDataLayer();
  const [isBookmarked, setBookmarked] = useState<boolean>(false);
  const { userLoggedIn, bookmarkedEpisodes } = useStoreState(
    UIStore,
    (store) => store
  );

  const checkIsBookmarked = () => {
    return !!bookmarkedEpisodes?.find((element) => element === slug);
  };

  useEffect(() => {
    if (source !== ContentSourceEnum.Episodes) {
      isBookmarked !== bookmarked && setBookmarked(bookmarked);
    }
  }, [bookmarked]);

  useEffect(() => {
    if (source === ContentSourceEnum.Episodes) {
      checkIsBookmarked() !== isBookmarked && setBookmarked(!isBookmarked);
    }
  }, [bookmarkedEpisodes]);

  const handleBookmark = async () => {
    if (!userLoggedIn) {
      return null;
    }
    try {
      !isBookmarked
        ? await client.post(apiPath[`BOOKMARK_${source}`], {
            slug,
          })
        : await client.delete(apiPath[`REMOVE_BOOKMARK_${source}`](slug));

      setBookmarked((prevState) => {
        const eventName = !prevState ? "save" : "unsave";
        pushToDataLayer(eventName, uiType);
        return !prevState;
      });

      if (source === ContentSourceEnum.Episodes) {
        await getBookmarkedEpisodes();
      }
    } catch (e) {
      // TODO: add error handling
      console.warn(e);
    }
  };
  return { isBookmarked, handleBookmark };
};
