import { SlugType } from "@/helpers/pageHelpers/Common/interface";
import {
  IconButtonSizes,
  IconButtonTypes,
} from "@/components/IconButton/IconButton.interface";

export enum ContentSourceEnum {
  Articles = "ARTICLES",
  Episodes = "EPISODES",
  Podcast = "PODCAST",
  Videos = "VIDEOS",
}

export enum UITypes {
  New = "new",
}

export interface BookmarkInterface {
  className?: string;
  isDark?: boolean;
  slug: SlugType;
  source: ContentSourceEnum;
  bookmarked?: boolean;
  hasCustomStyles?: boolean;
  variant?: IconButtonTypes;
  size?: IconButtonSizes;
  uiType?: UITypes;
}
