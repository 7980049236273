import React, { forwardRef } from "react";
import classNames from "classnames";

import {
  CheckboxInterface,
  CheckboxVersion,
} from "@/components/Checkbox/Checkbox.interface";
import CheckedIcon from "@/icons/check.svg";

import styles from "./Checkbox.module.scss";

export const Checkbox: React.FC<CheckboxInterface> = forwardRef(
  (
    {
      name,
      error,
      disabled,
      className,
      version = CheckboxVersion.first,
      onChange,
    },
    ref
  ) => (
    <label
      htmlFor={name}
      className={classNames(
        styles.checkbox,
        styles[version],
        error && styles.error,
        disabled && styles.disabled,
        className
      )}
    >
      <input
        name={name}
        id={name}
        type="checkbox"
        className={styles.input}
        ref={ref}
        disabled={disabled}
        onChange={onChange as any}
      />

      <CheckedIcon className={styles.icon} />
    </label>
  )
);

export default Checkbox;
