import { ComponentProps } from "@/helpers/componentProps";

export enum GridColumns {
  Two = "two-col",
  Three = "three-col",
  Twelve = "twelve-col",
}

export interface ContainerProps extends ComponentProps {
  grid?: GridColumns;
  className?: string;
}
