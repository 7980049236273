import classnames from "classnames";

import BookmarkIcon from "shared/icons/bookmark.svg";
import RoundedBookmarkIcon from "shared/icons/large-rounded-bookmark.svg";
import EmptyBookmarkIcon from "shared/icons/large-empty-rounded-bookmark.svg";
import IconButton from "shared/components/IconButton";
import { useBookmark } from "@/helpers/useBookmark";

import { BookmarkInterface } from "./BookmarkButton.interface";
import styles from "./BookmarkButton.module.scss";

export const BookmarkButton: React.FC<BookmarkInterface> = ({
  className,
  slug,
  source,
  bookmarked,
  hasCustomStyles,
  variant = "primary",
  size,
  uiType,
}) => {
  const { isBookmarked, handleBookmark } = useBookmark(
    slug,
    source,
    bookmarked,
    uiType
  );

  const renderIcon = () => {
    if (variant === "round" || variant === "square") {
      return isBookmarked ? (
        <RoundedBookmarkIcon key={isBookmarked} className={styles.icon} />
      ) : (
        <EmptyBookmarkIcon key={isBookmarked} className={styles.icon} />
      );
    } else {
      return <BookmarkIcon key={isBookmarked} />;
    }
  };

  if (hasCustomStyles) {
    return (
      <>
        <IconButton
          onClick={handleBookmark}
          className={classnames(
            styles.indicatorBookmark,
            "saveForLater"
          )}
          size="small"
          active={isBookmarked}
        >
          <BookmarkIcon key={isBookmarked} />
        </IconButton>
      </>
    );
  }

  return (
    <>
      <IconButton
        onClick={handleBookmark}
        variant={variant}
        size={size}
        active={isBookmarked}
        className={classnames(
          styles[variant],
          isBookmarked && styles.active,
          className,
          "saveForLater"
        )}
      >
        {renderIcon()}
      </IconButton>
    </>
  );
};

export default BookmarkButton;
